import { Button, LabeledSelect, Typography } from "@commonsku/styles";
import React, { useState } from "react";
import useFeatures from "../hooks/useFeatures";
import withReducers from "../store/withReducers";
import { reducers } from "../store/configureManageDistributorApp";
import { paymentModelOptions } from "./dashboard";
import Features from "../constants/Features";
import { toast } from 'react-toastify';

type ManageDistributorStatusProps = {
  distributor: {
    feature_id: string,
    payment_model: string,
  }
  onSave: (params: {paymentModel: string, featureId: string}) => Promise<void>;
};

const ManageDistributorStatus = ({
  distributor,
  onSave
}: ManageDistributorStatusProps) => {
  const [featureId, setFeatureId] = useState(distributor.feature_id);
  const [features] = useFeatures('DISTRIBUTOR');

  const featureDropdownItems = features.filter((f) => ['SOCIAL','FULL','ESSENTIAL','ADVANCED','ENTERPRISE'].includes(f.feature_name))
    .map((f) => {
      return {
        label: f.feature_description,
        value: f.feature_id
      }
    });

  const confirmAndSave = () => {
    if (confirm("Are you sure? You're about to update Stripe's Subscription")) {
      onSave({featureId, paymentModel: distributor.payment_model});
    }
  };

  const checkAndSet = ({ setToFeatureId }: {
    setToFeatureId: string,
  }) => {
    if (setToFeatureId === featureId) {
      return;
    }

    if (setToFeatureId === Features.DISTRIBUTOR_SOCIAL_FEATURE_ID) {
      toast.error('Cannot downgrade to Social Feature');
      setFeatureId(distributor.feature_id);
      return;
    }

    if (distributor.feature_id !== Features.DISTRIBUTOR_FULL_FEATURE_ID && setToFeatureId === Features.DISTRIBUTOR_FULL_FEATURE_ID) {
      toast.error('Cannot downgrade to Full Feature');
      setFeatureId(distributor.feature_id);
      return;
    }

    if (distributor.feature_id === Features.DISTRIBUTOR_ADVANCED_FEATURE_ID && setToFeatureId === Features.DISTRIBUTOR_ESSENTIAL_FEATURE_ID ||
      distributor.feature_id === Features.DISTRIBUTOR_ENTERPRISE_FEATURE_ID && [Features.DISTRIBUTOR_ESSENTIAL_FEATURE_ID, Features.DISTRIBUTOR_ADVANCED_FEATURE_ID].includes(setToFeatureId)
    ) {
      toast.error('Cannot downgrade Feature plan prior to contract renewal');
      setFeatureId(distributor.feature_id);
      return;
    }

    setFeatureId(setToFeatureId);
  }

  return (
    <>
      <Typography.H2>Current status</Typography.H2>
      <LabeledSelect
        label="Feature"
        name="featureId"
        value={featureDropdownItems.find((f) => f.value == featureId)}
        options={featureDropdownItems}
        onChange={(e)=>checkAndSet({setToFeatureId: e.value})}
        isClearable = {false}
      />

      <LabeledSelect
        label="Payment model"
        name="paymentModel"
        value={paymentModelOptions.find((m) => m.value == distributor.payment_model)}
        options={paymentModelOptions}
        isDisabled={true}
        isClearable={false}
      />
      <Button mt={40}
        onClick={() => {
          confirmAndSave()
        }}
      >Save</Button>
    </>
  );

};

export default withReducers(ManageDistributorStatus, reducers);
